var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hideBreadcrumbs)?_c('nav',{staticClass:"breadcrumb has-succeeds-separator",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',{class:{ 'is-active': _vm.isActiveRoute('Home') }},[_c('router-link',{attrs:{"to":{ name: 'Home' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('a',{attrs:{"href":href}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-home",attrs:{"aria-hidden":"true"}})])])]}}],null,false,2284705322)})],1),_vm._l((_vm.breadcrumbs),function(breadcrumb,breadcrumbIdx){return _c('li',{key:breadcrumbIdx,class:{ 'is-active': _vm.isActiveRoute(breadcrumb.name) }},[(
          !_vm.isActiveRoute(breadcrumb.name) && breadcrumb.hasOwnProperty('vuex')
        )?_c('router-link',{attrs:{"to":{ name: breadcrumb.name, params: _vm.$route.params },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
return [_c('a',{attrs:{"href":href}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.breadcrumbTitleFromVuex(breadcrumb, _vm.$route.params))))])]}}],null,true)}):(!_vm.isActiveRoute(breadcrumb.name))?_c('router-link',{attrs:{"to":{ name: breadcrumb.name, params: _vm.$route.params },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
return [_c('a',{attrs:{"href":href}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.breadcrumbTitle(breadcrumb, _vm.$route.params))))])]}}],null,true)}):_vm._e(),(
          _vm.isActiveRoute(breadcrumb.name) && breadcrumb.hasOwnProperty('vuex')
        )?_c('a',{attrs:{"href":"#","aria-current":"page"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.breadcrumbTitleFromVuex(breadcrumb, _vm.$route.params)))+" ")]):(_vm.isActiveRoute(breadcrumb.name))?_c('a',{attrs:{"href":"#","aria-current":"page"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.breadcrumbTitle(breadcrumb, _vm.$route.params)))+" ")]):_vm._e()],1)})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }